import React from 'react';
import { graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
    IntroQuote,
    Reference,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DasKonzeptHausDerAndacht = ({ data }) => (
    <InnerPage
        title="Das Konzept des Bahá’í-Hauses der Andacht "
        description=""
    >
        <IntroHolder
            title="Das Konzept des Bahá’í-Hauses der Andacht"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Andacht und Dienst',
                'current-item': 'Das Konzept des Bahá’í-Hauses der Andacht',
            }}
        >
            <IntroQuote>
                […] äußerlich betrachtet, ein stoffliches Gefüge ist, hat er
                geistige Wirkung. Er schmiedet Bande der Einheit von Herz zu
                Herz; er ist ein Sammelpunkt für die Menschenseelen...
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Im Haus der Andacht verwirklicht sich das Zusammenspiel von
                    Andacht und Dienst. Es wird von Bahá’u’lláh als „Aufgangsort
                    der Erwähnung Gottes“ bezeichnet und nimmt damit auch einen
                    zentralen Platz in der Gemeinschaft ein.
                </p>
                <div>
                    <p>
                        Die Bahá’í-Häuser der Andacht auf der ganzen Welt sind
                        dem Ziel gewidmet, die Hinwendung zu Gott mit dem Dienst
                        an der Menschheit zu verbinden. Es gibt acht regionale
                        oder kontinentale Bahá’í-Tempel, die sich in Australien,
                        Chile, Deutschland, Indien, Panama, Samoa, Uganda und
                        den Vereinigten Staaten befinden. Diese Gebäude stehen
                        allen Menschen offen und werden alljährlich von
                        Millionen Menschen besucht.
                    </p>
                    <p>
                        Während derzeit die meisten Bahá'i-Andachten in
                        Privatwohnungen und örtlichen Nachbarschaftszentren
                        stattfinden, werden nun als Folge der Vitalität des
                        Gemeindebildungsprozesses in verschiedenen Teilen der
                        Welt nationale und örtliche Bahá'i Häuser der Andacht
                        errichtet.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Jedes Haus der Andacht hat seine eigene unverwechselbare
                        Architektur, die von der heimischen Landschaft und
                        Kultur beeinflusst wird. Bahá'i Häuser der Andacht sind
                        Orte des Gebets, der Meditation und Besinnung. Sie sind
                        zur Lobpreisung Gottes konzipiert, offen für alle,
                        unabhängig von Religion oder Glaubensrichtung,
                        Nationalität oder Hintergrund.
                    </p>
                    <p>
                        In den Häuser der Andacht finden einfache
                        Andachtsprogramme statt, bei denen das Wort Gottes aus
                        den Heiligen Schriften der Bahá’í und aus den Schriften
                        anderer Weltreligionen rezitiert, gelesen oder gesungen
                        wird, ohne feste Sakramente, Rituale oder Predigten.
                    </p>
                </div>
                <Reference>
                    <p>1. Bahá’u’lláh, Botschaften aus 'Akká 11:13</p>
                    <p>
                        2. Botschaft des Universalen Hauses der Gerechtigkeit
                        vom 18.12.2014
                    </p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DasKonzeptHausDerAndacht;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "collage_Das-Haus-der-Andacht.jpg" }
        ) {
            ...fluidImage
        }
        collage: file(relativePath: { eq: "HausderAndacht.m.m.png" }) {
            ...fluidImage
        }
    }
`;
